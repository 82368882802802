<template>
  <div>
    <UserDetailItemEditCard confirmation-message="Werkrooster werd succesvol gewijzigd"
                            title="Bewerk werkrooster"
                            :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                            update-store-action="usersModule/updateUserWorkScheduleAndStartTimePerDay"
                            :update-store-action-payload="getPayload"
                            :userId="userId" :promises.sync="promises">
      <template v-slot:form-fields>
        <v-row>
          <v-col cols="12">
            <h2>Contractueel verplichte uren</h2>
          </v-col>
          <v-col cols="12" sm="7">
            <FetchOptionsSelect required persistent-placeholder label="Werkrooster"
                                action="workSchedulesModule/fetchWorkSchedules" v-model="workScheduleId"
                                item-text="name" item-value="id" @change="fetchWorkSchedule"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="7">
            <UserWorkScheduleTable :work-schedule="workSchedule"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2>Aanvangsuur werkdag</h2>
          </v-col>
          <v-col cols="12" lg="7">
            <WeekdaysTable v-model="startTimePerDay" editable :vertical="!$vuetify.breakpoint.xl">
              <template v-slot:editableCell="slotProps">
                <TimePicker :value="slotProps.cellValue" @input="slotProps.updateMethod($event)"
                            style="font-size: inherit;" clearable/>
              </template>
            </WeekdaysTable>
          </v-col>
        </v-row>
      </template>
    </UserDetailItemEditCard>
  </div>
</template>

<script>
import FetchOptionsSelect from "@/components/shared/fields/FetchOptionsSelect.vue";
import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"
import UserWorkScheduleTable from '@/components/userDetail/UserWorkScheduleTable.vue';
import WeekdaysTable from "../../../components/shared/WeekdaysTable.vue";
import TimePicker from "@/components/shared/fields/TimePicker.vue";
import {subMinutes} from "date-fns";
import {parseStringToDate} from "@/shared/utils/dateUtils";

export default {
  name: "UserDetailEditWorkSchedule",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      routeNames: routeNames,
      workScheduleId: null,
      workSchedule: {},
      startTimePerDay: {},
      promises: [
        // used for fetching the workschedule of the user
        this.$store.dispatch('usersModule/fetchUserWorkSchedule', this.userId).then((result) => {
          // selecting the active value of the workschedule dropdown
          this.workScheduleId = result.id
          // visualizing the current workschedule of the user
          this.workSchedule = result
        }),
        // used for fetching the default start hours of the user
        this.$store.dispatch('usersModule/fetchStartTimePerDay', this.userId).then((result) => {
          this.startTimePerDay = this.setDefaultStartTimePerDay(result.weekdaysWithTime)
        }),
      ]
    }
  },
  components: {
    FetchOptionsSelect,
    UserDetailItemEditCard,
    UserWorkScheduleTable,
    WeekdaysTable,
    TimePicker
  },
  methods: {
    fetchWorkSchedule() {
      // used for fetching (and visualizing) the selected workschedule from the dropdown
      this.promises.push(this.$store.dispatch('workSchedulesModule/fetchWorkScheduleById', this.workScheduleId)
          .then((result) => this.workSchedule = result))
    },
    setDefaultStartTimePerDay(startTimePerDay) {
      if (startTimePerDay) {
        return Object.fromEntries(Object.entries(startTimePerDay).map(([day, time]) => [day, time ? parseStringToDate(time) : null]))
      } else {
        return {
          MONDAY: null,
          TUESDAY: null,
          WEDNESDAY: null,
          THURSDAY: null,
          FRIDAY: null,
          SATURDAY: null,
          SUNDAY: null
        }
      }
    }
  },
  computed: {
    getPayload() {
      return {
        userId: this.userId,
        userWorkscheduleAndStartTimePerDayDTO: {
          workScheduleId: this.workScheduleId,
          weekdaysWithTimeMapDTO: {weekdaysWithTime: Object.fromEntries(Object.entries(this.startTimePerDay).map(([day, time]) => [day, time ? subMinutes(time, time.getTimezoneOffset()) : null]))}
        }
      }
    }
  }
}

</script>